import { isClient } from '@qctsw/utils'
import type { FormInst } from 'naive-ui'

export * from './route'
export * from './constants'
export * from './format'

/**
 * 触发NForm表单校验
 */
export async function validateHandle(el: FormInst | Ref<FormInst> | null | Ref<null>) {
  let isOk = false
  let errorContent = false
  try {
    await unref(el)?.validate((errors: any) => {
      if (!errors) {
        isOk = true
      }
      else {
        errorContent = errors
        if (isClient) {
          requestAnimationFrame(() => {
            document.getElementsByClassName('n-form-item-feedback--error')[0]?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })
          })
        }
      }
    })
  }
  catch {}

  return {
    isOk,
    errors: errorContent,
  }
}
